$(document).ready(function () {
  // ==================================================
  //  施工事例 スライダー
  // ==================================================
  const resultSlider = $("#js-resultSlider");
  if (resultSlider.length) {
    resultSlider.slick({
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: true,
      centerPadding: "0",
      slidesToShow: 2,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            centerMode: true,
            centerPadding: "0",
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
      ],
    });
  }
});
